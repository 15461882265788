@tailwind base;
@tailwind components;
@tailwind utilities;

body,html,#root{
  width:100%;
  height: 100%;
}


@keyframes anim-pop {
  0% {
      transform: scale(var(--btn-focus-scale,.98))
  }

  40% {
      transform: scale(1.02)
  }

  to {
      transform: scale(1)
  }
}
@media (prefers-reduced-motion: no-preference) {
  .anim-pop {
      animation: anim-pop .25s ease-out;
  }
  .anim-pop2 {
    animation: anim-pop 1s ease-out;
}
}
